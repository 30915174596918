import React from 'react'
import Layout from '../components/layout'
import SearchInput from '../components/SearchInput'
import Seo from "../components/seo"

const Canada = () => {
    return (
        <Layout>
            <Seo title="Canada" />

            <h1 className="title">Canada</h1>

            <SearchInput />

            <table>
                <tbody>
                    <tr className="thead title">
                        <td colSpan="2">IP Searching</td>
                    </tr>

                    <tr>
                        <td>Canadian patent database (search)</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.ic.gc.ca/opic-cipo/cpd/eng/search/basic.html">https://www.ic.gc.ca/opic-cipo/cpd/eng/search/basic.html</a></td>
                    </tr>

                    <tr>
                        <td>SPC search</td>
                        <td><a target="_blank" rel="noreferrer" href="https://pr-rdb.hc-sc.gc.ca/pr-rdb/index-eng.jsp">https://pr-rdb.hc-sc.gc.ca/pr-rdb/index-eng.jsp</a></td>
                    </tr>

                    <tr>
                        <td>Register of SPCs and applications</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.canada.ca/en/health-canada/services/drugs-health-products/drug-products/applications-submissions/guidance-documents/register-certificates.html">https://www.canada.ca/en/health-canada/services/drugs-health-products/drug-products/applications-submissions/guidance-documents/register-certificates.html</a></td>
                    </tr>

                    <tr className="thead title">
                        <td colSpan="2">Pharma Resources</td>
                    </tr>

                    <tr>
                        <td>Drug and Health Product Register</td>
                        <td><a target="_blank" rel="noreferrer" href="https://hpr-rps.hres.ca/">https://hpr-rps.hres.ca/</a></td>
                    </tr>

                    <tr>
                        <td>PMNOC patent linkage register</td>
                        <td><a target="_blank" rel="noreferrer" href="https://pr-rdb.hc-sc.gc.ca/pr-rdb/search-recherche.do?lang=en">https://pr-rdb.hc-sc.gc.ca/pr-rdb/search-recherche.do?lang=en</a></td>
                    </tr>

                    <tr>
                        <td>CA Generic submissions under review</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.canada.ca/en/health-canada/services/drug-health-product-review-approval/generic-submissions-under-review.html">https://www.canada.ca/en/health-canada/services/drug-health-product-review-approval/generic-submissions-under-review.html</a></td>
                    </tr>
                </tbody>
            </table>
        </Layout>
    )
}

export default Canada